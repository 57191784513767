import React, { useEffect, useState } from "react";
import educacion_1 from "../../../../img/educacion-1.png";
import icono_calendario from "../../../../img/icono-calendario.png";
import "moment/locale/es-mx";
import moment from "moment";
const Post = ({ posts, goToPage, isChange }) => {
  const [post, setPost] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setPost(posts);
    }, 1000);
  }, []);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [isChange]);
  return (
    <>
      {loading ? (
        <div class="row">
          <div class="col-12 col-sm-12 col-md-5"></div>
          <div class="col-12 col-sm-12 col-md-2">
            <div class="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-5"></div>
        </div>
      ) : (
        posts.map((item) => (
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-4 mb-4 blog-fade"
            onClick={() => goToPage(item.id)}
          >
            <div class="entrada-blog">
              <img src={item.image} alt="PERCAPITA" class="img-fluid" />
              <div class="cuadro-blog py-2">
                <div class="text-center">
                  <h6 class="rLight pt-1">{item.category}</h6>
                </div>
              </div>
            </div>
            <div class="mt-3 fecha-pub">
              <h6 class="oRegular">
                <img src={icono_calendario} alt="PERCAPITA" class="img-fluid" />
                {moment(item.datePost).format("LL")}
              </h6>
            </div>
            <div class="mt-2">
              <h5 class="txt-azul-marino rExtraBold">{item.name}</h5>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default Post;
