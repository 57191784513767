import React, { useEffect, useState } from 'react';

export const UserContext = React.createContext();

const UserProvider = props => {
  const [userMain, setUserMain] = useState({
    userId: '',
    nombre: '',
  });

  const updateUser = valor => {
    setUserMain(valor);
    localStorage.setItem('userVenta', JSON.stringify(valor));
  };

  useEffect(() => {
    if (localStorage.getItem('userVenta')) {
      const userLocal = JSON.parse(localStorage.getItem('userVenta'));
      setUserMain(userLocal);
    }
  }, []);

  return (
    <UserContext.Provider value={{userMain, updateUser}}>
      {props.children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {};

export default UserProvider;
