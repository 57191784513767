import React,{useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import PersonIcon from '@mui/icons-material/Person';
import CurtainsClosedIcon from '@mui/icons-material/CurtainsClosed';
import { UserContext } from "./../context/UserProvider";

export const FloatUser = () => {
    const { userMain, updateUser } = React.useContext(UserContext);
    function closeSession() {
        updateUser({
            ...userMain,
            nombre: null,
            userId: null,
          });

          return  window.location.replace(`https://app.percapita.mx/login-cambaceo/null/null/logout`);
    }
    return (
    <>
        {userMain.nombre !== null && userMain.nombre !== ''? 
              <div className="float">
              <Fab variant="extended" color="primary" aria-label="add">
                  <PersonIcon sx={{ mr: 1 }} />
                  {userMain.nombre}
              </Fab>
              <Fab variant="extended" color="warning" aria-label="add" onClick={closeSession}>
                  <CurtainsClosedIcon sx={{ mr: 1 }} />
                  Salir
              </Fab>
        
              </div>
            : ''}

    </>

    )
}
