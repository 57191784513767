import React, { useEffect, useState, useRef } from "react";
import { Footer } from "../../front/Footer";
import { Navbar } from "../../front/Navbar";
import icon_percapita from "../../../img/icon-percapita.png";
import familianosotros from "../../../img/familia-nosotros.png";
import elcreditorapido from "../../../img/el-credito-rapido.png";
import logogde from "../../../img/logo-gde.png";
import logobco from "../../../img/logo-bco.png";
import solicitudlinea from "../../../img/solicitud-linea.png";
import confirmacelular from "../../../img/confirma-celular.png";
import subedocumentos from "../../../img/sube-documentos.png";
import prestamo from "../../../img/prestamo.png";
import productos from "../../../img/productos.png";
import logo from "../../../img/logo.png";
import icono_hombre from "../../../img/icono-hombre.png";
import comision from "../../../img/comision.png";
import minium from "../../../img/minium.png";
import maximium from "../../../img/maximium.png";
import estadoCuenta from "../../../img/estadoCuenta.png";
//
import logo_pabs2 from "../../../img/logo_pabs.png";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import icono_mujer from "../../../img/icono_mujer.png";
import tasaInteres from "../../../img/tasaInteres.png";
import frequencyPayment from "../../../img/frequencyPayment.png";
import icono_percapita from "../../../img/icono-percapita.png";
import ana_luisa from "../../../img/ana-luisa.png";
import angelica_luis from "../../../img/angelica-luis.png";
import { useAnimation, useInView, motion } from "framer-motion";
import logo_azul from "../../../img/logo-azul.png";
import facilidad from "../../../img/facilidad.png";
import respaldo from "../../../img/respaldo.png";
import servicio from "../../../img/servicio.png";
import seguridad from "../../../img/seguridad.png";
import lealtad from "../../../img/lealtad.png";
import plazos from "../../../img/plazos.png";
import acuerdo from "../../../img/acuerdo.png";
import ine from "../../../img/ine.png";
import nomina from "../../../img/nomina.png";
import domicilio from "../../../img/domicilio.png";
import edad from "../../../img/edad.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "800px",

  maxHeight: "500px",
  background: "radial-gradient(circle at top left, #005593, #36c9d0)",
  boxShadow: 29,
  overflowY: "auto",
};
export const CreditoPersonal = () => {
  const [open2, setOpen2] = React.useState(false);
  const handleClose = () => setOpen2(false);
  const ref = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const inView = useInView(ref);
  const inView2 = useInView(ref2);
  const inView3 = useInView(ref3);
  const inView4 = useInView(ref4);
  const inView5 = useInView(ref5);
  const inView6 = useInView(ref6);
  const inView7 = useInView(ref7);
  const animation = useAnimation();
  const animation2 = useAnimation();
  const animation3 = useAnimation();
  const animation4 = useAnimation();
  const animation5 = useAnimation();
  const animation6 = useAnimation();
  const animation7 = useAnimation();
  const animation11 = useAnimation();
  const animation12 = useAnimation();
  const animation13 = useAnimation();
  const animation14 = useAnimation();

  const animation8 = useAnimation();
  const animation9 = useAnimation();
  const animation10 = useAnimation();
  const animation15 = useAnimation();
  const animation16 = useAnimation();
  const animation17 = useAnimation();
  const animation18 = useAnimation();
  const animation19 = useAnimation();
  const animation20 = useAnimation();
  const animation21 = useAnimation();
  const animation22 = useAnimation();
  useEffect(() => {
    if (inView) {
      console.log(1);
      animation.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.8 },
      });
      animation2.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.2 },
      });
      animation3.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.6 },
      });
    }
    if (!inView) {
      animation.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation2.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation3.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
  }, [inView]);
  useEffect(() => {
    if (inView2) {
      animation4.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1 },
      });
    }
    if (!inView2) {
      animation4.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }

    if (inView4) {
      animation9.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.8 },
      });
      animation10.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.2 },
      });
    }
    if (!inView4) {
      animation9.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation10.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
  }, [inView2, inView3, inView4]);
  useEffect(() => {
    if (inView3) {
      console.log("inView3");
      animation5.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.8 },
      });
      animation6.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.2 },
      });
      animation7.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.6 },
      });
      animation8.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 2 },
      });
    }
    if (!inView3) {
      animation5.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation6.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation7.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation8.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }

    if (inView5) {
      console.log("inView3");
      animation11.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1 },
      });
      animation12.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.4 },
      });
      animation13.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.8 },
      });
      animation14.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 2.2 },
      });
    }
    if (!inView5) {
      animation11.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation12.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation13.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation14.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
    if (inView6) {
      animation15.start({
        x: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1 },
      });
    }
    if (!inView6) {
      animation15.start({
        x: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
    if (inView7) {
      animation16.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1 },
      });
      animation17.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.4 },
      });
      animation18.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.8 },
      });
      animation19.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 2.2 },
      });
    }
    if (!inView7) {
      animation16.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation17.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation18.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation19.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
  }, [inView3, inView5, inView6, inView7]);

  useEffect(() => {
    document.title = "Percapita - Nosotros";
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    >
      <Navbar />
      <Modal
        keepMounted
        open={open2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* //<ScrollView width="100%" height="100%"> */}
        <Grid container spacing={2} sx={style}>
          <div className="modal-content">
            <div className="d-flex justify-content-end p-1  ">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-center mb-3">
                <img
                  src={logo}
                  alt="PRADO'S"
                  width="220"
                  className="img-fluid"
                />
              </div>
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 p-3" style={{ background: "#ff00006E" }}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={logo_pabs2}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      Tengo título PABS{" "}
                    </h4>
                    <h5 className="rLight mt-4">
                      Tu crédito ya está <br />
                      Pre-autorizado
                    </h5>

                    <div className="mt-4">
                      <a
                        href="https://app.percapita.mx/Titulo?utm_source=percapita.mx&utm_medium=search&utm_campaign=none"
                        className="btn btn-primary btn-conoce-azul"
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                    <div className="mt-4">
                      <p className="rLight mt-4">
                        ¿Qué es un Título PABS? <br />
                        <a
                          href="https://www.pabsmr.org/que-es-un-titulo-pabs/"
                          target="_blank"
                          style={{ color: "white" }}
                        >
                          Click aqui
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-3" style={{ background: "#ffff0040" }}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={icono_mujer}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      Quiero un <br /> crédito personal{" "}
                    </h4>
                    <h5 className="rLight mt-4">
                      Tu crédito en <br />
                      48 horas*
                    </h5>

                    <div className="mt-4">
                      <a
                        href="https://app.percapita.mx/Personal?utm_source=percapita.mx&utm_medium=search&utm_campaign=none"
                        className="btn btn-primary btn-conoce-azul"
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Modal>
      <div class="d-none d-sm-none d-md-block" ref={ref}>
        <div class="bg-nosotros py-5">
          <div class="container pt-5">
            <div class="row py-5">
              <div class="text-center pt-5">
                <motion.div animate={animation} class="mb-4">
                  <img
                    src={icon_percapita}
                    alt="PERCAPITA"
                    class="img-fluid icono-headers"
                  />
                  <h1 class="rBlack display-4">Crédito Personal</h1>
                  <h2 class="rLight h3">
                    Con Préstamo Personal Percapita, te ofrecemos un crédito
                    simple con el cual podrás disponer de dinero en efectivo con
                    un trámite ágil y de mínimos requisitos, para que lleves a
                    cabo tus proyectos personales. Tú decides el plazo de tu
                    crédito y tus pagos siempre serán fijos.
                  </h2>
                </motion.div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none" ref={ref2}>
        <div class="bg-nosotros-phone py-3">
          <div class="container">
            <div class="row">
              <motion.div class="text-center" animate={animation4}>
                <div class="mb-4">
                  <img
                    src={icon_percapita}
                    alt="PERCAPITA"
                    class="img-fluid icon-percapita-nosotro"
                  />
                </div>
                <div class="">
                  <h1 class="rBlack display-2">Crédito Personal</h1>
                  <h2 class="rLight h3">
                    Con Préstamo Personal Percapita, te ofrecemos un crédito
                    simple con el cual podrás disponer de dinero en efectivo con
                    un trámite ágil y de mínimos requisitos, para que lleves a
                    cabo tus proyectos personales. Tú decides el plazo de tu
                    crédito y tus pagos siempre serán fijos.
                  </h2>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-sm-none d-md-block">
        <div class=" pt-3">
          <div class="container" ref={ref3}>
            <div class="row ">
              <div class="text-center">
                <motion.div animate={animation5} class="mb-4">
                  <h1 class="rBlack txt-azul-marino display-3">
                    Caracteristicas
                  </h1>
                </motion.div>
                <div></div>
              </div>
            </div>
            <div class="row">
              <div class="container py-3">
                <div class="row mb-5">
                  <motion.div
                    animate={animation5}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={icono_hombre}
                            alt="SOLICITUD EN LÍNEA"
                            class="img-fluid mb-3 icono-solicitud"
                          />
                          {/* <p class="txt-azul txt-paso rRegular">Paso 01</p> */}
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Dirigido a:
                          </h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">Personas físicas.</h2>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    animate={animation5}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={prestamo}
                            alt="CONFIRMA TU CELULAR"
                            class="img-fluid mb-3 icono-celular"
                          />
                          {/* <p class="txt-azul txt-paso rRegular">Paso 02</p> */}
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Medio de disposición:{" "}
                          </h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">
                            Transferencias y orden de{" "}
                          </h2>
                          <h2 class="txt-gris h5">
                            pago a cobrar en ventanilla.
                          </h2>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    animate={animation5}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={comision}
                            alt="SUBE TUS DOCUMENTOS"
                            class="img-fluid mb-3 icono-documentos"
                          />
                        </div>
                        <div class="mb-3"></div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">
                            5% de comisión de apertura.
                          </h2>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    animate={animation5}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={minium}
                            alt="TU PRESTAMO EN 24 HRS."
                            class="img-fluid mb-3 icono-prestamo"
                          />
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Monto mínimo:
                          </h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">
                            $2,000.00 pesos mexicanos.
                          </h2>
                        </div>
                      </div>
                    </div>
                  </motion.div>

                  <motion.div
                    animate={animation5}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={maximium}
                            alt="TU PRESTAMO EN 24 HRS."
                            class="img-fluid mb-3 icono-prestamo"
                          />
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Monto máximo:
                          </h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">
                            $35,000.00 pesos mexicanos.
                          </h2>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    animate={animation5}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={tasaInteres}
                            alt="TU PRESTAMO EN 24 HRS."
                            class="img-fluid mb-3 icono-prestamo"
                          />
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Tasa de interés desde el 70.00% anual.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                  {/*  */}
                  <motion.div
                    animate={animation5}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={frequencyPayment}
                            alt="TU PRESTAMO EN 24 HRS."
                            class="img-fluid mb-3 icono-prestamo"
                          />
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Frecuencia de pago:{" "}
                          </h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">Quincenal y semanal.</h2>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    animate={animation5}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={plazos}
                            alt="TU PRESTAMO EN 24 HRS."
                            class="img-fluid mb-3 icono-prestamo"
                          />
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Plazos:{" "}
                          </h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">Desde 3 a 12 meses.</h2>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    animate={animation5}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={estadoCuenta}
                            alt="TU PRESTAMO EN 24 HRS."
                            class="img-fluid mb-3 icono-prestamo"
                          />
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Estado de cuenta
                          </h6>
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Mensual.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </div>
                <div class="row">
                  <div class="text-center">
                    {/* <a href="https://app.percapita.mx/?from=Personal" class="btn btn-primary btn-solicitar">
                                    <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                                </a> */}
                    {/* <button disabled class="btn btn-primary btn-solicitar">
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                            </button> */}
                    <a
                      href="#"
                      onClick={() => setOpen2(true)}
                      class="btn btn-primary btn-conoce-azul"
                    >
                      <h6 class="rBlack txt-azul-marino pt-1">Solicitar</h6>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-sm-none d-md-block bg-logo">
        <div class="container py-5">
          <div class="row" ref={ref4}>
            <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-10 ">
              <div class="text-center">
                <h1 class="rBlack txt-azul-marino display-4">
                  Beneficios{" "}
                  <h3 class="txt-azul-marino rLight">
                    de tener tu <b class="rBlack">préstamo con </b>
                  </h3>
                </h1>
                <img src={logo_azul} alt="PERCAPITA" class="img-fluid" />
              </div>
              <div class="row pt-5">
                <motion.div
                  animate={animation9}
                  class="col-12 col-sm-12 col-md-12 col-lg-4"
                >
                  <div class="bg-cuadro-1">
                    <div class="text-center">
                      <img
                        src={facilidad}
                        alt="FACILIDAD"
                        class="img-fluid icono-facilidad"
                      />
                    </div>
                  </div>
                  <div class="">
                    <h3 class="txt-azul-marino rExtraBold h4">Facilidad</h3>
                    <h5 class="txt-gris rRegular h5">
                      Sin trámites burtocráticos
                    </h5>
                    <h5 class="txt-gris rRegular h5">o complicados.</h5>
                  </div>
                </motion.div>
                <motion.div
                  animate={animation9}
                  class="col-12 col-sm-12 col-md-12 col-lg-4"
                >
                  <div class="bg-cuadro-2">
                    <div class="text-center">
                      <img
                        src={respaldo}
                        alt="RESPALDO"
                        class="img-fluid icono-respaldo"
                      />
                    </div>
                  </div>
                  <div class="txt-beneficios-new">
                    <h3 class="txt-azul-marino rExtraBold h4">Respaldo</h3>
                    <h5 class="txt-gris rRegular h5">Empresa legalmente</h5>
                    <h5 class="txt-gris rRegular h5">constituida.</h5>
                  </div>
                </motion.div>
                <motion.div
                  animate={animation9}
                  class="col-12 col-sm-12 col-md-12 col-lg-4"
                >
                  <div class="bg-cuadro-1">
                    <div class="text-center">
                      <img
                        src={servicio}
                        alt="SERVICIO"
                        class="img-fluid icono-facilidad"
                      />
                    </div>
                  </div>
                  <div class="txt-beneficios-new">
                    <h3 class="txt-azul-marino rExtraBold h4">Servicio</h3>
                    <h5 class="txt-gris rRegular h5">Autorizaciones en </h5>
                    <h5 class="txt-gris rRegular h5">minutos.</h5>
                  </div>
                </motion.div>
              </div>
              <div class="row pt-5">
                <motion.div
                  animate={animation9}
                  class="col-12 col-sm-12 col-md-12 col-lg-4"
                >
                  <div class="bg-cuadro-2">
                    <div class="text-center">
                      <img
                        src={seguridad}
                        alt="SEGURIDAD"
                        class="img-fluid icono-respaldo"
                      />
                    </div>
                  </div>
                  <div class="txt-beneficios-new">
                    <h3 class="txt-azul-marino rExtraBold h4">Seguridad</h3>
                    <h5 class="txt-gris rRegular h5">Facilidad en pagos</h5>
                  </div>
                </motion.div>
                <motion.div
                  animate={animation9}
                  class="col-12 col-sm-12 col-md-12 col-lg-4"
                >
                  <div class="bg-cuadro-1">
                    <div class="text-center">
                      <img
                        src={facilidad}
                        alt="CONFIANZA"
                        class="img-fluid icono-facilidad"
                      />
                    </div>
                  </div>
                  <div class="txt-beneficios-new">
                    <h3 class="txt-azul-marino rExtraBold h4">Confianza</h3>
                    <h5 class="txt-gris rRegular h5">No tenemos cobros </h5>
                    <h5 class="txt-gris rRegular h5">ocultos.</h5>
                  </div>
                </motion.div>
                <motion.div
                  animate={animation9}
                  class="col-12 col-sm-12 col-md-12 col-lg-4"
                >
                  <div class="bg-cuadro-2">
                    <div class="text-center">
                      <img
                        src={lealtad}
                        alt="LEALTAD"
                        class="img-fluid icono-respaldo"
                      />
                    </div>
                  </div>
                  <div class="txt-beneficios-new">
                    <h3 class="txt-azul-marino rExtraBold h4">Lealtad</h3>
                    <h5 class="txt-gris rRegular h5">Aportaciones</h5>
                    <h5 class="txt-gris rRegular h5">congeladas.</h5>
                  </div>
                </motion.div>
                <motion.div
                  animate={animation9}
                  class="col-12 col-sm-12 col-md-12 col-lg-4"
                >
                  <div class="bg-cuadro-2">
                    <div class="text-center">
                      <img
                        src={respaldo}
                        alt="LEALTAD"
                        class="img-fluid icono-respaldo"
                      />
                    </div>
                  </div>
                  <div class="txt-beneficios-new">
                    <h3 class="txt-azul-marino rExtraBold h4">
                      Sin penalización{" "}
                    </h3>
                    <h5 class="txt-gris rRegular h5">
                      por liquidación anticipada.
                    </h5>
                  </div>
                </motion.div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
          </div>
        </div>
      </div>

      <div class="d-none d-sm-none d-md-block" ref={ref5}>
        <div class=" pt-3">
          <div class="container">
            <div class="row ">
              <motion.div animate={animation11} class="text-center">
                <h1 class="rBlack txt-azul-marino display-3">Requisitos</h1>
                <div></div>
              </motion.div>
            </div>
            <div class="row">
              <div class="container py-3">
                <div class="row mb-5">
                  <motion.div
                    animate={animation11}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={ine}
                            alt="SOLICITUD EN LÍNEA"
                            class="img-fluid mb-3 icono-solicitud"
                          />
                          {/* <p class="txt-azul txt-paso rRegular">Paso 01</p> */}
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            identificación oficial vigente. IFE/INE
                          </h6>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    animate={animation12}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={domicilio}
                            alt="CONFIRMA TU CELULAR"
                            class="img-fluid mb-3 icono-celular"
                          />
                          {/* <p class="txt-azul txt-paso rRegular">Paso 02</p> */}
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Comprobante de domicilio:{" "}
                          </h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">No mayor a 3 meses.</h2>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    animate={animation13}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={nomina}
                            alt="SUBE TUS DOCUMENTOS"
                            class="img-fluid mb-3 icono-documentos"
                          />
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Comprobante de ingresos:{" "}
                          </h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">
                            Pueden ser nóminas, estados de cuenta, facturas,
                            declaración anual, notas, carta laboral, entre
                            otros.
                          </h2>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    animate={animation14}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={edad}
                            alt="TU PRESTAMO EN 24 HRS."
                            class="img-fluid mb-3 icono-prestamo"
                          />
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">Edad</h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">
                            Mínima de 21 y máxima de 65 años.
                          </h2>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                  <div class="mt-3 text-center">
                          <h6 class=" h6 rExtraBold">
                          Aplican términos y condiciones, consulta en: <a href="/Terminos-y-condiciones ">https://percapita.mx/Terminos-y-condiciones </a> 
                          </h6>
                        </div>
                </div>
                <div class="row">
                  <div class="text-center">
                    {/* <a href="https://app.percapita.mx/?from=Personal" class="btn btn-primary btn-solicitar">
                                    <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                                </a> */}
                    {/* <button disabled class="btn btn-primary btn-solicitar">
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                            </button> */}
                    <a
                      href="#"
                      onClick={() => setOpen2(true)}
                      class="btn btn-primary btn-conoce-azul"
                    >
                      <h6 class="rBlack txt-azul-marino pt-1">Solicitar</h6>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none">
        <div class=" pt-5">
          <div class="container pt-2">
            <div class="row">
              <div class="text-center">
                <motion.div a class="mb-2">
                  <h1 class="rBlack txt-azul-marino display-3">
                    Caracteristicas
                  </h1>
                </motion.div>
                <div></div>
              </div>
            </div>

            <div class="row">
              <div class="container py-3">
                <div class="row mb-5">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3">
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={icono_hombre}
                            alt="SOLICITUD EN LÍNEA"
                            class="img-fluid mb-3 icono-solicitud"
                          />
                          {/* <p class="txt-azul txt-paso rRegular">Paso 01</p> */}
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Dirigido a:
                          </h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">Personas físicas.</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3">
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={prestamo}
                            alt="CONFIRMA TU CELULAR"
                            class="img-fluid mb-3 icono-celular"
                          />
                          {/* <p class="txt-azul txt-paso rRegular">Paso 02</p> */}
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Medio de disposición:{" "}
                          </h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">
                            Transferencias y orden de{" "}
                          </h2>
                          <h2 class="txt-gris h5">
                            pago a cobrar en ventanilla.
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3">
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={comision}
                            alt="SUBE TUS DOCUMENTOS"
                            class="img-fluid mb-3 icono-documentos"
                          />
                        </div>
                        <div class="mb-3"></div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">
                            5% de comisión de apertura.
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3">
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={minium}
                            alt="TU PRESTAMO EN 24 HRS."
                            class="img-fluid mb-3 icono-prestamo"
                          />
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Monto mínimo:
                          </h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">
                            $2,000.00 pesos mexicanos.
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3">
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={maximium}
                            alt="TU PRESTAMO EN 24 HRS."
                            class="img-fluid mb-3 icono-prestamo"
                          />
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Monto máximo:
                          </h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">
                            $35,000.00 pesos mexicanos.
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3">
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={tasaInteres}
                            alt="TU PRESTAMO EN 24 HRS."
                            class="img-fluid mb-3 icono-prestamo"
                          />
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Tasa de interés desde el 70.00% anual.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3">
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={frequencyPayment}
                            alt="TU PRESTAMO EN 24 HRS."
                            class="img-fluid mb-3 icono-prestamo"
                          />
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Frecuencia de pago:{" "}
                          </h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">Quincenal y semanal.</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3">
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={plazos}
                            alt="TU PRESTAMO EN 24 HRS."
                            class="img-fluid mb-3 icono-prestamo"
                          />
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Plazos:{" "}
                          </h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">Desde 3 a 12 meses.</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3">
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={estadoCuenta}
                            alt="TU PRESTAMO EN 24 HRS."
                            class="img-fluid mb-3 icono-prestamo"
                          />
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Estado de cuenta
                          </h6>
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Mensual.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="text-center">
                    {/* <a href="https://app.percapita.mx/?from=Personal" class="btn btn-primary btn-solicitar">
                                    <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                                </a> */}
                    {/* <button disabled class="btn btn-primary btn-solicitar">
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                            </button> */}
                    <a
                      href="#"
                      onClick={() => setOpen2(true)}
                      class="btn btn-primary btn-conoce-azul"
                    >
                      <h6 class="rBlack txt-azul-marino pt-1">Solicitar</h6>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none">
        <div class="bg-logo">
          <div class="container py-5">
            <div class="row pt-5">
              <div class="text-center">
                <h1 class="rBlack txt-azul-marino display-4">
                  Beneficios{" "}
                  <h3 class="txt-azul-marino rLight">
                    de tener tu <b class="rBlack">préstamo con </b>
                  </h3>
                </h1>
                <img src={logo_azul} alt="PERCAPITA" class="img-fluid" />
              </div>

              <div class="row pt-5">
                <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="ms-4">
                    <div class="bg-cuadro-1">
                      <div class="text-center">
                        <img
                          src={facilidad}
                          alt="FACILIDAD"
                          class="img-fluid icono-facilidad"
                        />
                      </div>
                    </div>
                    <div class="">
                      <h3 class="txt-azul-marino rExtraBold h4">Facilidad</h3>
                      <h5 class="txt-gris rRegular h5">
                        Sin trámites burtocráticos
                      </h5>
                      <h5 class="txt-gris rRegular h5">o complicados.</h5>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="ms-4">
                    <div class="bg-cuadro-2">
                      <div class="text-center">
                        <img
                          src={respaldo}
                          alt="RESPALDO"
                          class="img-fluid icono-respaldo"
                        />
                      </div>
                    </div>
                    <div class="txt-beneficios-new">
                      <h3 class="txt-azul-marino rExtraBold h4">Respaldo</h3>
                      <h5 class="txt-gris rRegular h5">Empresa legalmente</h5>
                      <h5 class="txt-gris rRegular h5">constituida.</h5>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="ms-4">
                    <div class="bg-cuadro-1">
                      <div class="text-center">
                        <img
                          src={servicio}
                          alt="SERVICIO"
                          class="img-fluid icono-facilidad"
                        />
                      </div>
                    </div>
                    <div class="txt-beneficios-new">
                      <h3 class="txt-azul-marino rExtraBold h4">Servicio</h3>
                      <h5 class="txt-gris rRegular h5">Autorizaciones</h5>
                      <h5 class="txt-gris rRegular h5">en minutos.</h5>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="ms-4">
                    <div class="bg-cuadro-2">
                      <div class="text-center">
                        <img
                          src={seguridad}
                          alt="SEGURIDAD"
                          class="img-fluid icono-respaldo"
                        />
                      </div>
                    </div>
                    <div class="txt-beneficios-new">
                      <h3 class="txt-azul-marino rExtraBold h4">Seguridad</h3>
                      <h5 class="txt-gris rRegular h5">Facilidad en pagos</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pt-5">
                <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="ms-4">
                    <div class="bg-cuadro-1">
                      <div class="text-center">
                        <img
                          src={facilidad}
                          alt="CONFIANZA"
                          class="img-fluid icono-facilidad"
                        />
                      </div>
                    </div>
                    <div class="txt-beneficios-new">
                      <h3 class="txt-azul-marino rExtraBold h4">Confianza</h3>
                      <h5 class="txt-gris rRegular h5">No tenemos cobros </h5>
                      <h5 class="txt-gris rRegular h5">ocultos.</h5>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="ms-4">
                    <div class="bg-cuadro-2">
                      <div class="text-center">
                        <img
                          src={lealtad}
                          alt="LEALTAD"
                          class="img-fluid icono-respaldo"
                        />
                      </div>
                    </div>
                    <div class="txt-beneficios-new">
                      <h3 class="txt-azul-marino rExtraBold h4">Lealtad</h3>
                      <h5 class="txt-gris rRegular h5">Aportaciones</h5>
                      <h5 class="txt-gris rRegular h5">congeladas.</h5>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="ms-4">
                    <div class="bg-cuadro-2">
                      <div class="text-center">
                        <img
                          src={respaldo}
                          alt="LEALTAD"
                          class="img-fluid icono-respaldo"
                        />
                      </div>
                    </div>
                    <div class="txt-beneficios-new">
                      <h3 class="txt-azul-marino rExtraBold h4">
                        Sin penalización{" "}
                      </h3>
                      <h5 class="txt-gris rRegular h5">
                        por liquidación anticipada.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-block d-sm-block d-md-none">
        <div class="container py-5">
          <div class="row">
            <div class="text-center mb-2">
              <h1 class="rBlack txt-azul-marino display-3">Requisitos</h1>
            </div>

            <div class="row">
              <div class="container py-3">
                <div class="row mb-5">
                  <div
                    animate={animation11}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={ine}
                            alt="SOLICITUD EN LÍNEA"
                            class="img-fluid mb-3 icono-solicitud"
                          />
                          {/* <p class="txt-azul txt-paso rRegular">Paso 01</p> */}
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            identificación oficial vigente. IFE/INE
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    animate={animation12}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={domicilio}
                            alt="CONFIRMA TU CELULAR"
                            class="img-fluid mb-3 icono-celular"
                          />
                          {/* <p class="txt-azul txt-paso rRegular">Paso 02</p> */}
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Comprobante de domicilio:{" "}
                          </h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">No mayor a 3 meses.</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    animate={animation13}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={nomina}
                            alt="SUBE TUS DOCUMENTOS"
                            class="img-fluid mb-3 icono-documentos"
                          />
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">
                            Comprobante de ingresos:{" "}
                          </h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">
                            Pueden ser nóminas, estados de cuenta, facturas,
                            declaración anual, notas, carta laboral, entre
                            otros.
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    animate={animation14}
                    class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
                  >
                    <div class="mx-3">
                      <div class="bg-cards py-3">
                        <div class="pt-3">
                          <img
                            src={edad}
                            alt="TU PRESTAMO EN 24 HRS."
                            class="img-fluid mb-3 icono-prestamo"
                          />
                        </div>
                        <div class="mb-3">
                          <h6 class="txt-azul-marino h5 rExtraBold">Edad</h6>
                        </div>
                        <div class="rRegular ">
                          <h2 class="txt-gris h5">
                            Mínima de 21 y máxima de 65 años.
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3 text-center">
                          <h6 class=" h6 rExtraBold">
                          Aplican términos y condiciones, consulta en: <a href="/Terminos-y-condiciones ">https://percapita.mx/Terminos-y-condiciones </a> 
                          </h6>
                        </div>
                </div>
                <div class="row">
                  <div class="text-center">
                    {/* <a href="https://app.percapita.mx/?from=Personal" class="btn btn-primary btn-solicitar">
                                    <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                                </a> */}
                    {/* <button disabled class="btn btn-primary btn-solicitar">
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                            </button> */}
                    <a
                      href="#"
                      onClick={() => setOpen2(true)}
                      class="btn btn-primary btn-conoce-azul"
                    >
                      <h6 class="rBlack txt-azul-marino pt-1">Solicitar</h6>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <Footer />
    </motion.div>
  );
};
