import React from "react";
const Paginate = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div class="row">
      <div class="text-end">
        <nav aria-label="...">
          <ul class="pagination justify-content-end">
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={
                  number === currentPage ? "page-item active" : "page-item"
                }
              >
                <a
                  onClick={() => paginate(number)}
                  href="#"
                  class="page-link active"
                >
                  {number}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Paginate;
